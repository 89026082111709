// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/Blog/blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/Blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-company-about-us-js": () => import("./../../../src/templates/Company/about-us.js" /* webpackChunkName: "component---src-templates-company-about-us-js" */),
  "component---src-templates-company-work-with-us-js": () => import("./../../../src/templates/Company/work-with-us.js" /* webpackChunkName: "component---src-templates-company-work-with-us-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/Contact/contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-demo-demo-js": () => import("./../../../src/templates/Demo/demo.js" /* webpackChunkName: "component---src-templates-demo-demo-js" */),
  "component---src-templates-home-home-js": () => import("./../../../src/templates/Home/home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-landing-pages-acelera-pyme-index-js": () => import("./../../../src/templates/Landing-Pages/Acelera-Pyme/index.js" /* webpackChunkName: "component---src-templates-landing-pages-acelera-pyme-index-js" */),
  "component---src-templates-landing-pages-free-quick-start-package-index-js": () => import("./../../../src/templates/Landing-Pages/Free-Quick-Start-Package/index.js" /* webpackChunkName: "component---src-templates-landing-pages-free-quick-start-package-index-js" */),
  "component---src-templates-landing-pages-kit-digital-index-js": () => import("./../../../src/templates/Landing-Pages/Kit-Digital/index.js" /* webpackChunkName: "component---src-templates-landing-pages-kit-digital-index-js" */),
  "component---src-templates-legal-legal-page-js": () => import("./../../../src/templates/Legal/legal-page.js" /* webpackChunkName: "component---src-templates-legal-legal-page-js" */),
  "component---src-templates-pricing-pricing-js": () => import("./../../../src/templates/Pricing/pricing.js" /* webpackChunkName: "component---src-templates-pricing-pricing-js" */),
  "component---src-templates-product-product-js": () => import("./../../../src/templates/Product/product.js" /* webpackChunkName: "component---src-templates-product-product-js" */),
  "component---src-templates-releases-release-js": () => import("./../../../src/templates/Releases/release.js" /* webpackChunkName: "component---src-templates-releases-release-js" */),
  "component---src-templates-releases-releases-js": () => import("./../../../src/templates/Releases/releases.js" /* webpackChunkName: "component---src-templates-releases-releases-js" */),
  "component---src-templates-services-services-js": () => import("./../../../src/templates/Services/services.js" /* webpackChunkName: "component---src-templates-services-services-js" */),
  "component---src-templates-solutions-ecommerce-js": () => import("./../../../src/templates/Solutions/ecommerce.js" /* webpackChunkName: "component---src-templates-solutions-ecommerce-js" */)
}

